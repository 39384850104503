@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.map-element-item {
  &__preview {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    margin-right: 16px;
  }

  &__round {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $light-color;
    margin-right: 16px;
    path {
      fill: $base-text-color;
    }
  }
}

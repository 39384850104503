@import 'styles/mixins.scss';
@import 'styles/variables.scss';
@import 'styles/typography.scss';

.feature-details {
  .modal {
    z-index: 1;
    background-color: transparent;
    padding: 124px 24px 60px 0;
    justify-content: end;
    align-items: start;

    &__body {
      height: 100%;
      align-items: stretch;
      width: 410px;
      background-color: $dark-color;
      border-radius: 10px;

      &__close-icon {
        width: 32px;
        height: 32px;
        border-radius: 100px;

        &:hover {
          background-color: $pink-color;
        }
      }
    }
  }

  &__table-view {
    .modal {
      background: none;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    max-width: 346px;
    @include flex(column, false, flex-start);
    gap: 14px;
    margin: auto;
  }

  &__header {
    width: 100%;

    h1 {
      margin-bottom: 4px;
    }
  }

  &__coordinates {
    width: 100%;
    position: relative;
    margin-top: 4px;
    @include flex(column, false, flex-start);
    gap: 4px;

    &__values {
      color: $red-color;
      @include font(12px, 500, 16px, false);
      cursor: pointer;
    }

    &__setting-icon {
      position: absolute;
      top: 5px;
      right: 0;
      cursor: pointer;
    }
  }

  &__title {
    @extend .tpg-c2;
    color: $title-color;
  }

  &__events {
    width: 100%;

    .datepicker {
      &-label {
        @include font(14px, 500, 20px, false);
        color: $base-color;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }

      &-container {
        .custom-datepicker__input {
          height: 32px;
          padding: 5px 13px;
          @include font(16px, 400, 24px, false);

          @include placeholder {
            color: $light-grey;
          }
        }

        .react-datepicker-popper {
          margin-left: 8px;
        }
      }
    }

    .select {
      margin-top: 12px;
    }
  }

  &__date {
    margin-top: 12px;

    .custom-datepicker__input {
      background-color: $light-color;
    }
  }

  .sidebar__filter {
    &_list {
      gap: 12px;
    }

    .subtitle {
      @include font(16px, 700, 14px, false);
      color: $title-color;
      margin-bottom: 12px;
    }
  }

  &__color {
    width: 100%;
  }

  &__radio-group {
    width: 100%;

    .sidebar__filter_list {
      @include flex();
      flex-direction: row;
      gap: 24px;

      .checkbox-container__input {
        border-radius: 50%;
      }
    }
  }

  &__statuses {
    .sidebar__filter_list {
      @include flex(column, false, flex-start);
      gap: 12px;
    }
  }

  &__type-object,
  &__reports,
  &__clients,
  &__transparent,
  &__attributes,
  &__media {
    width: 100%;
    @include flex(column, false, flex-start);
    gap: 12px;
  }

  textarea {
    min-height: 128px;

    @include placeholder {
      color: $grey-03;
    }
  }

  &__actions {
    width: 100%;
    @include flex(row, space-between, false);

    .astra-button {
      max-width: 157px;
      border-radius: 5px;
      @include font(16px, 600, 20px, false);
    }
  }

  &__tooltip {
    opacity: 1 !important;
    border-radius: 5px;
    background-color: $white-color !important;
    padding: 0 !important;

    &__actions {
      @include flex(column, false, flex-start);

      button {
        width: 100%;
        border: none;
        outline: none;
        background-color: $white-color;
        padding: 8px 15px;
        text-align: start;
        cursor: pointer;

        &:hover {
          background-color: $pink-color;
        }
      }
    }

    .rc-tooltip {
      &-content {
        width: 150px;
        border-radius: 5px;
      }

      &-inner {
        padding: 5px 0;
        color: $base-color;
        border-radius: 5px;
        background-color: $white-color;
        border: none;
      }
    }
  }
}

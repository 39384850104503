@import 'styles/mixins.scss';
@import 'styles/variables.scss';
@import 'styles/typography.scss';

.type-item {
  @include flex(false, space-between, false);
  padding-bottom: 8px;
  color: $light-grey;

  &__content {
    @include flex(column, false, flex-start);
    gap: 4px;
    color: $title-color;

    &__name {
      @extend .tpg-c1;
    }
  }
}

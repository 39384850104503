@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.map-sidebar {
  height: 100%;
  position: relative;
  @include flex(row, false, flex-start);
  @include transition(width 0.3s);
  width: 80px;

  &__tabs {
    padding-top: 24px;
    padding-right: 20px;
    padding-left: 20px;
    width: 80px;
    background-color: $light-color;
    height: 100%;
    gap: 27px;
    @include flex(column, flex-start, center);

    &__icon {
      @include flex(row, center, false);
      cursor: pointer;
      width: 30px;
      height: 30px;
      color: $base-text-color !important;

      &.active {
        color: $bright-product-color !important;
        border-radius: 5px;
      }

      &:hover {
        color: $dark-product-color !important;
      }

      &:disabled {
        color: $bright-color !important;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__bottom-tabs {
    background-color: $light-color;
    height: 100%;
    gap: 24px;
    @include flex(column, flex-end, center);

    &__icon {
      @include flex(row, center, false);
      cursor: pointer;
      width: 40px;
      height: 40px;

      path {
        fill: $title-color;
      }

      &.active {
        border-radius: 5px;
      }
    }
  }

  &__filters {
    width: 100%;
    @include flex();
    padding: 0 16px;
    margin-top: 12px;
    gap: 17px;
  }

  &__body {
    margin: 24px;
    width: 410px;
    height: calc(100% - 84px);
    border-radius: 10px;
    @include flex(column, false, false);
    background-color: $dark-color;

    position: absolute;
    left: 80px;
    z-index: 3;

    &_title {
      width: 100%;
      @include flex(row, space-between, false);
      padding: 0 16px;

      h2 {
        @include font(32px, 600, 32px, false);
        color: $base-color;
      }

      .close_icon {
        @include flex(false, center, false);
        width: 32px;
        height: 32px;
        border-radius: 16px;
        @include transition(background-color 0.2s ease-in-out);

        &:hover {
          cursor: pointer;
          background-color: $pink-color;
        }
      }
    }

    &_content {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    .geospoof-loader {
      margin-top: 20px;
    }
  }
}

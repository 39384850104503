@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.imagery-body {
  height: 100%;
  width: 100%;

  .astra-button {
    @extend .astra-button;
    width: 40%;
    padding: 10px;
    @include font(14px, 600, 20px, false);
  }

  &__filter {
    width: 100%;

    &__item {
      .astra-button {
        width: 100%;
      }
    }
  }

  &__controls {
    @include flex(row, space-around);
    width: 100%;
    margin-bottom: 10px;
  }

  &__items {
    max-height: calc(100% - 90px);
    overflow-y: auto;
    width: 100%;

    &__row {
      //@include flex(false, false, center);
      margin-bottom: 12px;

      &:hover {
        opacity: 0.8;
      }

      &__info {
        margin-right: 10px;
        overflow: hidden;
      }

      &__preview {
        border-radius: 10px;
        width: 48px;
        height: 48px;
        margin-right: 16px;

        img {
          border-radius: 10px;
        }
      }

      &__fly-icon {
        @include flex(row, center, false);
        width: 35px;
        height: 35px;
        cursor: pointer;

        svg path {
          fill: $base-color;
        }
      }

      div:last-child {
        margin-left: auto;
      }
    }
  }

  &__footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: $light-color;
    width: 100%;
    height: 32px;
    padding: 6px 16px;
  }
}

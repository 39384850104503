@import 'styles/variables.scss';

.operational-selector {
  position: relative;
  top: 12px !important;
  left: 56px;
  width: 336px;
}

.operational-datepicker {
  left: 40px;
  border-radius: 10px;
  border: 1px solid $light-text-color !important;
}

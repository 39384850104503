@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.map_footer {
  background-color: $dark-color;
  color: $base-text-color;
  width: 100%;
  height: 36px;

  path {
    fill: $base-text-color;
  }
}

.copy-icon path {
  fill: $light-text-color;
}
